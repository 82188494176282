import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import clsx from "clsx";
export var RenderMode;
(function (RenderMode) {
    RenderMode["Selector"] = "selector";
    RenderMode["Summary"] = "summary";
})(RenderMode || (RenderMode = {}));
const AgentLogo = (props) => {
    const { src, locked } = props;
    if (src) {
        return (_jsxs("picture", { className: "agent__logo", children: [_jsx("img", { src: src, alt: "" }), locked && (_jsx("svg", { className: "icon icon--lock", children: _jsx("use", { "xlink:href": "#lock" }) })), ";"] }));
    }
    return (_jsxs("div", { className: "agent__logo", children: [locked && (_jsx("svg", { className: "icon icon--lock", children: _jsx("use", { "xlink:href": "#lock" }) })), ";"] }));
};
function AgentItem({ renderMode = RenderMode.Selector, agent, allowHighlight, children, }) {
    const showFeeQuote = false;
    const { no_of_live_listings, no_of_sold_listings, avg_difference_in_percentage, turnaround_weeks, fee_quote_percent, agent_name, agent_logo, agent_logo_lqip, is_sponsored, } = agent;
    const className = clsx(["item", "item__agent"], (is_sponsored && allowHighlight) && "isSponsored");
    return (_jsxs("div", { className: className, children: [_jsxs("div", { className: "agent__details", children: [_jsx(AgentLogo, { src: renderMode === RenderMode.Summary ? agent_logo : agent_logo_lqip, locked: renderMode === RenderMode.Selector }), _jsx("span", { className: "agent__title", children: renderMode === RenderMode.Summary ? agent_name : "Property agent" })] }), _jsxs("div", { className: "agent__stat", children: [_jsx("span", { className: "stat__title", children: "Avg. sale time in weeks" }), _jsx("span", { className: "stat__figure", children: turnaround_weeks })] }), _jsxs("div", { className: "agent__stat", children: [_jsx("span", { className: "stat__title", children: "Avg. sold vs valuation" }), _jsx("span", { className: "stat__figure", children: `${Number(avg_difference_in_percentage) + 100}%` })] }), (no_of_live_listings || no_of_sold_listings) && (_jsxs("div", { className: "agent__stat", children: [_jsxs("span", { className: "stat__title", children: ["Properties listed in", _jsx("wbr", {}), " the last 6 months"] }), _jsx("span", { className: "stat__figure", children: no_of_live_listings + no_of_sold_listings })] })), showFeeQuote && (_jsxs("div", { className: "agent__stat", children: [_jsx("span", { className: "stat__title", children: "Fee quote:" }), _jsx("span", { className: "stat__figure", children: `${fee_quote_percent ? fee_quote_percent : "XX.XX"}%` })] })), children && (_jsx("div", { className: "agent__stat", children: children }))] }));
}
AgentItem.defaultProps = {
    allowHighlight: true,
};
export default AgentItem;
